<template>
  <div class="body">
    <div class="main">
      <div class="u_m--b30">
        <h1 class="title">{{ $t('login__forgetPassword') }}</h1>
        <el-steps :active="active" finish-status="success">
          <el-step :title="$t('login__usernamePlaceholder')"></el-step>
          <el-step :title="$t('ui_wap_text_096')"></el-step>
          <el-step :title="$t('ui_wap_text_097')"></el-step>
        </el-steps>
      </div>
      <el-form :model="modelRef" ref="formRef">
        <div class="step1" v-if="active == '1'">
          <el-form-item label="" prop="username">
            <el-input
              v-model.trim="modelRef.username"
              :placeholder="$t('login__usernamePlaceholder')"
              @keydown.enter="handleSubmit"
            >
              <template #prefix><i class=" el-icon-user el-input__icon"></i></template>
            </el-input>
            <el-button
              type="primary"
              class="submit"
              @click="submit()"
              :disabled="!modelRef.username"
            >
              {{ $t('btn_next') }}
            </el-button>
          </el-form-item>
        </div>
        <!-- step1end -->
        <div class="step2" v-if="active == '2'">
          <el-form-item label="" prop="code">
            <el-input
              v-model.trim="code"
              :placeholder="$t('tip_code_blank')"
              @keydown.enter="handleSubmit"
            >
              <template #prefix><i class="el-icon-key  el-input__icon"></i></template>
            </el-input>
            <el-button type="primary" class="submit" @click="back(1)">
              {{ $t('btn_back') }}
            </el-button>
            <el-button type="primary" class="submit" @click="verification()">
              {{ $t('btn_next') }}
            </el-button>
          </el-form-item>
        </div>
        <!-- step2end -->
        <div class="step2" v-if="active == '3'">
          <el-form-item label="" prop="code">
            <el-input
              v-model.trim="password"
              :placeholder="$t('login__passwordPlaceholder')"
              class="u_m--b10"
              @keydown.enter="handleSubmit"
            >
              <template #prefix><i class="el-icon-key  el-input__icon"></i></template>
            </el-input>
            <el-input
              v-model.trim="passwordAgn"
              :placeholder="$t('ui_input_pwd_again')"
              @keydown.enter="handleSubmit"
            >
              <template #prefix><i class="el-icon-key  el-input__icon"></i></template>
            </el-input>
            <div class="tip" v-if="password != passwordAgn">
              <i class="el-icon-warning el-input__icon" />
              {{ $t('sysmsg_pwd_check_diff') }}
            </div>
            <el-button
              type="primary"
              class="submit"
              :class="[{enabled: !!password == !!passwordAgn}]"
              :disabled="!password || !passwordAgn || password != passwordAgn"
              @click="editps()"
            >
              {{ $t('btn_submit') }}
            </el-button>
          </el-form-item>
        </div>
        <!-- footer -->
        <el-form-item>
          <div class="disFlexb">
            <!-- <div class="link" @click="$router.push('/register')">
              {{ $t('login__registerNew') }}
            </div> -->
            <div class="link" @click="$router.push('/login')">
              {{ $t('ui_hint_acc_login') }}
            </div>
          </div>
        </el-form-item>
        <el-dropdown ref="dropdown">
          <span class="el-dropdown-link">
            {{ $t(name) }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu v-model="lang">
              <el-dropdown-item v-for="lang in actions" :key="lang.id" @click="onSelect(lang)">{{
                lang.name
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {ILoginParams} from '@/model';
import {i18n} from '@/common/web';
import {ElMessage, ElDropdown, ElDropdownMenu, ElDropdownItem, ElStep, ElSteps} from 'element-plus';
import {loadLanguageAsync} from '@/common/web/locale/language';
import {forgetinfo} from '@/services/server/wap/forget';
import {
  ISendforgetpassword,
  ICheckforgetpassword,
  // ISendforgetacc,
  ISetforgetpassword
} from '@/model/forget';
@Options({
  data() {
    return {
      // active: '2'
    };
  },
  components: {
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ElStep,
    ElSteps
  }
})
export default class Forget extends Vue {
  submitLoading = false;
  active = '1';
  code = '';
  password = '';
  passwordAgn = '';
  secret = '';
  modelRef: ILoginParams = {
    username: '',
    password: ''
  };
  dropdown = {};
  actions: {
    name: string;
    key: string;
    className?: string;
  }[] = [];
  name = '简体中文';
  lang = i18n.global.locale;
  async mounted() {
    const handleLangs = new Map([
      ['zh_cn', 'zh-CN'],
      ['vn', 'vn'],
      ['en_us', 'en'],
      ['th_th', 'th']
    ]);
    // eslint-disable-next-line
    if (window.hasOwnProperty('LANGLIST') && Array.isArray(window.LANGLIST)) {
      // eslint-disable-next-line
      window.LANGLIST.map((lang: {text: string; key: string}) => {
        const key = handleLangs.get(lang.key) || 'zh-CN';
        const item: {
          name: string;
          key: string;
          className: string;
        } = {
          name: lang.text,
          key,
          className: key === this.lang ? 'current-ajskfjaslkfj' : ''
        };
        this.actions.push(item);
      });
    }
    this.lang = localStorage.getItem('language') || window.defaultLangCode;
    this.name =
      this.actions.filter(v => v.key === (handleLangs.get(this.lang) || this.lang))[0].name ||
      '简体中文';
    // console.log(this.dropdown, 'dropdown');
    await this.onSelect({key: handleLangs.get(this.lang) || this.lang, name: this.name});
  }
  async onSelect(item: {name: string; key: string}) {
    // console.log('item: ', item);
    // this.showLang = false;
    await loadLanguageAsync(item.key);
    i18n.global.locale = item.key;
    this.lang = item.key;
    this.name = item.name;
    this.$forceUpdate();
    localStorage.setItem('language', item.key);
  }
  back(page: string) {
    this.active = page;
  }
  async submit() {
    // this.active = '2';
    const result = await forgetinfo.sendforgetpassword<ISendforgetpassword>({
      account: this.modelRef.username,
      field_name: 'phone_number'
    });
    if (result instanceof Error) {
      ElMessage(result.message);
    } else {
      ElMessage(result.message);
      this.active = '2';
    }

    return;
  }
  async verification() {
    const result = await forgetinfo.checkforgetpassword<ICheckforgetpassword>({
      code: this.code,
      account: this.modelRef.username,
      field_name: 'phone_number'
    });
    if (result instanceof Error) {
      ElMessage(result.message);
    } else {
      ElMessage(result.message);
      this.active = '3';
      this.secret = result.secret || '';
    }
    return;
  }
  async editps() {
    const result = await forgetinfo.setforgetpassword<ISetforgetpassword>({
      secret: this.secret,
      password: this.password,
      confirm: this.passwordAgn
    });
    if (result instanceof Error) {
      ElMessage(result.message);
    } else {
      ElMessage(result.message);
      this.active = '0';
    }
    return;
  }
}
</script>
<style lang="scss" scoped>
.body {
  background: url('../../../assets/web/bglogin.jpg');
  width: 100vw;
  height: 100vh;
}
.main {
  min-width: 368px;
  padding: 36px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .title {
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 30px;
    text-align: center;
    color: #ffffff;
    /* background-image:-webkit-linear-gradient(right,#FFFFFF,#0f47e6, #FFFFFF);
        -webkit-background-clip: text;
        -webkit-text-fill-color:transparent; */
  }
  .submit {
    width: 100%;
  }
  .link {
    color: #fff;
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.back,
.go {
  transition: all 2s;
  cursor: pointer;
}
.el-button--primary {
  margin: 10px 0 0;
}
:deep(.el-step__main) {
  margin-left: -25px;
}
.u_m--b30 {
  margin-bottom: 30px;
}
.u_m--b10 {
  margin-bottom: 10px;
}
.step1,
.step2 {
  width: 360px;
}
.tip {
  color: $rc;
}
</style>
