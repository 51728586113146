
import {Options, Vue} from 'vue-class-component';
import {ILoginParams} from '@/model';
import {i18n} from '@/common/web';
import {ElMessage, ElDropdown, ElDropdownMenu, ElDropdownItem, ElStep, ElSteps} from 'element-plus';
import {loadLanguageAsync} from '@/common/web/locale/language';
import {forgetinfo} from '@/services/server/wap/forget';
import {
  ISendforgetpassword,
  ICheckforgetpassword,
  // ISendforgetacc,
  ISetforgetpassword
} from '@/model/forget';
@Options({
  data() {
    return {
      // active: '2'
    };
  },
  components: {
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ElStep,
    ElSteps
  }
})
export default class Forget extends Vue {
  submitLoading = false;
  active = '1';
  code = '';
  password = '';
  passwordAgn = '';
  secret = '';
  modelRef: ILoginParams = {
    username: '',
    password: ''
  };
  dropdown = {};
  actions: {
    name: string;
    key: string;
    className?: string;
  }[] = [];
  name = '简体中文';
  lang = i18n.global.locale;
  async mounted() {
    const handleLangs = new Map([
      ['zh_cn', 'zh-CN'],
      ['vn', 'vn'],
      ['en_us', 'en'],
      ['th_th', 'th']
    ]);
    // eslint-disable-next-line
    if (window.hasOwnProperty('LANGLIST') && Array.isArray(window.LANGLIST)) {
      // eslint-disable-next-line
      window.LANGLIST.map((lang: {text: string; key: string}) => {
        const key = handleLangs.get(lang.key) || 'zh-CN';
        const item: {
          name: string;
          key: string;
          className: string;
        } = {
          name: lang.text,
          key,
          className: key === this.lang ? 'current-ajskfjaslkfj' : ''
        };
        this.actions.push(item);
      });
    }
    this.lang = localStorage.getItem('language') || window.defaultLangCode;
    this.name =
      this.actions.filter(v => v.key === (handleLangs.get(this.lang) || this.lang))[0].name ||
      '简体中文';
    // console.log(this.dropdown, 'dropdown');
    await this.onSelect({key: handleLangs.get(this.lang) || this.lang, name: this.name});
  }
  async onSelect(item: {name: string; key: string}) {
    // console.log('item: ', item);
    // this.showLang = false;
    await loadLanguageAsync(item.key);
    i18n.global.locale = item.key;
    this.lang = item.key;
    this.name = item.name;
    this.$forceUpdate();
    localStorage.setItem('language', item.key);
  }
  back(page: string) {
    this.active = page;
  }
  async submit() {
    // this.active = '2';
    const result = await forgetinfo.sendforgetpassword<ISendforgetpassword>({
      account: this.modelRef.username,
      field_name: 'phone_number'
    });
    if (result instanceof Error) {
      ElMessage(result.message);
    } else {
      ElMessage(result.message);
      this.active = '2';
    }

    return;
  }
  async verification() {
    const result = await forgetinfo.checkforgetpassword<ICheckforgetpassword>({
      code: this.code,
      account: this.modelRef.username,
      field_name: 'phone_number'
    });
    if (result instanceof Error) {
      ElMessage(result.message);
    } else {
      ElMessage(result.message);
      this.active = '3';
      this.secret = result.secret || '';
    }
    return;
  }
  async editps() {
    const result = await forgetinfo.setforgetpassword<ISetforgetpassword>({
      secret: this.secret,
      password: this.password,
      confirm: this.passwordAgn
    });
    if (result instanceof Error) {
      ElMessage(result.message);
    } else {
      ElMessage(result.message);
      this.active = '0';
    }
    return;
  }
}
