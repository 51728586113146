import doFetch from '../../fetch';
const ACCPATH = '/api/acc/front';
const Game = 'api/game/v1';
/**
 * * 忘記帳號 - 輸入手機號 1-1
 *   忘记密码 - 发送验证码 2-1
 *   忘记密码 - 确认验证码 2-2
 *   忘记密码 - 重设密码 3-3
 *  forget ps or Acc
 * @param data
 */
const actions = {
  sendforgetacc: async <T>(data: object) =>
    await doFetch<T>(`${Game}/verification/send_forget_acc`, 'POST', data),
  sendforgetpassword: async <T>(data: object) =>
    await doFetch<T>(`${Game}/verification/send_forget_password`, 'POST', data),
  checkforgetpassword: async <T>(data: object) =>
    await doFetch<T>(`${Game}/verification/check_forget_password`, 'POST', data),
  setforgetpassword: async <T>(data: object) =>
    await doFetch<T>(`${Game}/verification/set_forget_password`, 'POST', data)
};
export const forgetinfo = actions;
